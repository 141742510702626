import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import Checkbox from "components/checkbox";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import env from "variables/config";

function SignInDefault() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const login = () => {
        axios
            .post(`${env.apiUrl}/auth/login`, {
                email: email.toLocaleLowerCase(),
                password: password,
            })
            .then((response: any) => {
                console.log(response);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("name", response.data.fullName);
                localStorage.setItem("id", response.data._id);
                localStorage.setItem("isAdmin", response.data.isAdmin);
                localStorage.removeItem("waiting-checkout");
                navigate("/dashboard");
            })
            .catch((error: any) => {
                console.log(error);
                toast.error("Email ou mot de passe incorrect");
            });
    };

    useEffect(() => {
        const currentHost = window.location.hostname;

        if (currentHost?.includes("tab.fidiz.io")) {
            window.location.href = "https://app.fidiz.io/tablet-hub";
            return;
        }
    }, []);

    return (
        <Default
            maincard={
                <div className="flex h-full w-full items-center justify-center px-4 md:mx-0 md:px-0">
                    {/* Sign in section */}
                    <div className="mx-auto w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
                        <ToastContainer />
                        <h3 className="text-xl lg:text-3xl mb-5 font-bold dark:text-white">
                            Se connecter
                        </h3>
                        {/* Email */}
                        <div className="flex mt-5">
                            <input
                                id="email"
                                key={"email"}
                                type="text"
                                placeholder="email*"
                                className="p-2 rounded-xl w-[600px] h-[60px] border-xl border border-[#1f2937]"
                                onChange={(e: any) => setEmail(e.target.value)}
                            />
                        </div>
                        {/* Password */}
                        <div className="flex mt-5">
                            <input
                                id="password"
                                key={"password"}
                                type="password"
                                placeholder="Mot de passe*"
                                className="p-2 rounded-xl w-[600px] h-[60px] border-xl border border-[#1f2937]"
                                onChange={(e: any) =>
                                    setPassword(e.target.value)
                                }
                            />
                        </div>
                        {/* Checkbox */}
                        <div className="mb-4 flex items-center justify-between px-2">
                            <div className="mt-2 flex items-center">
                                <Checkbox extra="checked:bg-blue-400" />
                                <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                                    Rester connecté
                                </p>
                            </div>
                            <p
                                className="mt-1 text-xs cursor-pointer"
                                onClick={() =>
                                    navigate("/auth/forgot-password")
                                }
                            >
                                Mot de passe oublié ?
                            </p>
                        </div>
                        <button
                            id="connect-btn"
                            className="linear mt-4 w-full rounded-xl bg-primary text-white font-bold py-3 text-base transition duration-200 hover:bg-primary/70 active:bg-primary/80 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                            onClick={() => login()}
                        >
                            Je me connecte
                        </button>
                    </div>
                </div>
            }
        />
    );
}

export default SignInDefault;
