import TalkImg from "assets/img/talk-logo.svg";
import shootingImg1 from "assets/img/shooting-1.jpg";
import shootingImg2 from "assets/img/shooting-2.jpg";
import shootingImg3 from "assets/img/shooting-3.jpg";

function Default(props: { maincard: JSX.Element }) {
    const { maincard } = props;
    return (
        <div className="relative h-screen w-full grid grid-cols-12 no-scrollbar">
            <div className="hidden md:block bg-gray-50 col-span-6 rounded-sm h-full">
                <img
                    className={`pl-10 mt-2 w-[150px] rounded-xl`}
                    src={TalkImg}
                    alt="Fidiz logo"
                />
                <div className="mt-8 flex flex-col items-center justify-center mx-auto">
                    <h1 className="text-4xl font-bold text-[#131316] mb-2 text-center">
                        Soyez trouvé, soyez choisi et devenez
                    </h1>
                    <h2 className="text-4xl font-bold text-[#5167F6] underline">
                        incontournable.
                    </h2>
                </div>
                <div className="mt-8 flex flex-col lg:flex-row items-center justify-around p-10 bg-gray-50 rounded-xl">
                    {/* Left Section */}
                    <div className="relative flex flex-col items-center">
                        <img
                            src={shootingImg1}
                            alt="Woman in green sweater"
                            className="w-72 h-96 bg-gray-80 rounded-[50px] object-cover"
                        />
                        <span className="absolute font-bold z-50 top-2 right-[-30px] bg-[#5167F6] text-white px-4 py-2 rounded-full">
                            Technologie
                        </span>
                        <div className="absolute bottom-[-20px] left-10 bg-white p-4 rounded-xl shadow-lg">
                            <div className="flex items-center space-x-2">
                                <span className="relative flex h-3 w-3">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                </span>
                                <span className="text-sm font-medium text-gray-900">
                                    Fidiz en direct
                                </span>
                            </div>
                            <p className="text-sm text-gray-600">
                                Notre Shooting photo
                            </p>
                            <p className="text-sm font-bold">
                                L'astrolabe, Paris
                            </p>
                        </div>
                    </div>

                    {/* Middle Section */}
                    <div className="relative flex flex-col items-center mt-10 lg:mt-0">
                        <img
                            src={shootingImg2}
                            alt="Woman in blue sweater"
                            className="z-10 w-72 h-96 bg-gray-90 rounded-[50px] object-cover"
                        />
                        <span className="absolute font-bold z-50 top-[70px] right-[-40px] bg-blue-400 text-white px-4 py-2 rounded-full">
                            + Communication
                        </span>
                    </div>

                    {/* Right Section */}
                    <div className="relative flex flex-col items-center mt-10 lg:mt-0">
                        <img
                            src={shootingImg3}
                            alt="Man in green sweater"
                            className="w-72 h-96 bg-gray-100 rounded-[50px] object-cover"
                        />
                        <div className="absolute top-[-20px] bg-white p-3 rounded-full shadow-lg">
                            <span className="text-lg font-bold text-[#5167F6]">
                                = croissance 🚀
                            </span>
                        </div>
                    </div>
                </div>
                {/* <div className="flex flex-col items-center justify-center">
                    <img
                        className="mt-5 md:mt-10 mx-auto w-[200%]"
                        src={campaignImg}
                        alt="Campaign"
                    />
                </div> */}
            </div>
            <div className="col-span-12 md:col-span-6 md:ml-10">{maincard}</div>
        </div>
    );
}

export default Default;
