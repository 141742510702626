import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    };
};

export const loginIfPhoneExists = async (
    phone: string,
    restaurantId: string,
) => {
    const res: any = await axios
        .post("/tablet/phone", { phone, restaurantId }, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const registerTabletAccount = async (
    email: string,
    phone: string,
    restaurantId: string,
) => {
    const res: any = await axios
        .post("/tablet/register", { email, phone, restaurantId }, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const requestTabletGift = async (
    restaurantId: string,
    rewardId: string,
    email: string,
) => {
    const res: any = await axios
        .post(
            "/tablet/request-gift",
            { restaurantId, rewardId, email },
            config(),
        )
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const validateCouponTablet = async (
    restaurantId: string,
    code: string,
) => {
    const res: any = await axios
        .post("/tablet/activate-coupon", { restaurantId, code }, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getTabletRewards = async (restaurantId: string) => {
    const res: any = await axios
        .get(`/tablet/rewards?restaurantId=${restaurantId}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getTabletNextReward = async (restaurantId: string) => {
    const res: any = await axios
        .get(`/tablet/next-reward?restaurantId=${restaurantId}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const verifyTabletCode = async (code: string) => {
    const res: any = await axios
        .get(`/tablet/verify-code?code=${code}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getTabletUrl = async (phone: string) => {
    const res: any = await axios
        .get(`/tablet/tablet-url?phone=${phone}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};
