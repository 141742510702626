import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TbExternalLink, TbTrash } from "react-icons/tb";

import tabletteImg from "assets/img/tablette.jpeg";
import tabletManagerImg from "assets/img/tablet-manager.png";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { BiPlusCircle } from "react-icons/bi";
import { getMyRestaurant, updateMyRestaurant } from "interfaces/restaurant";
import LiveChatFooter from "components/helper/LiveChatFooter";
import { toast } from "react-toastify";
import { getMyMagicLink } from "interfaces/user";
import { IoMdCopy } from "react-icons/io";
import GoBack from "components/actions/GoBack";

const rewards = [
    { points: 30, reward: "Une portion de frites" },
    { points: 40, reward: "Une boisson" },
    { points: 50, reward: "Nuggets x6, Hot-dog, Petit wrap de poulet" },
    { points: 70, reward: "Un dessert" },
    { points: 150, reward: "Un menu offert" },
];

const TabletOnboarding = () => {
    const navigate = useNavigate();
    const [saveStatus, setSaveStatus] = useState<string>("none");
    const [tabletManagerLink, setTabletManagerLink] = useState(null);
    const [restaurant, setRestaurant] = useState<any>({
        rewards: [],
        googlePlaceId: null,
    });

    const handleRewardChange = (e: any, index: number) => {
        const rewards = restaurant.rewards;
        rewards[index][e.target.id.split("-")[0]] = e.target.value;
        setRestaurant({
            ...restaurant,
            rewards,
        });
    };

    const initRestaurant = async () => {
        const restaurant = await getMyRestaurant();
        setRestaurant(restaurant);
    };

    useEffect(() => {
        if (restaurant === null) return;
        if (restaurant.name === "") return;
        setSaveStatus("saving");
        const delayDebounceFn = setTimeout(async () => {
            const res = await updateMyRestaurant(restaurant);
            if (res) setSaveStatus("saved");
            else setSaveStatus("error");
        }, 1500);
        return () => clearTimeout(delayDebounceFn);
    }, [restaurant]);

    const initTabletManagerLink = async () => {
        const res: any = await getMyMagicLink();
        if (res?.data?.magicLink)
            setTabletManagerLink(
                `https://app.fidiz.io/tablet-manager/${res.data.magicLink}`,
            );
    };

    useEffect(() => {
        if (localStorage.getItem("token") === null) navigate("/auth/login");
        initRestaurant();
        initTabletManagerLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GoBack />
            <div className="mt-4 flex h-full w-full flex-col gap-[20px] rounded-[20px] sm:mt-3 xl:flex-row">
                <div className="h-full w-full rounded-[20px]">
                    <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
                        {/* Main data */}
                        <div className="mt-1 grid w-full grid-cols-8 gap-5">
                            <div className="col-span-12">
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
                                    <div className="col-span-1">
                                        <article className="overflow-hidden rounded-lg shadow transition">
                                            <img
                                                alt=""
                                                src={tabletteImg}
                                                className="h-56 w-full object-cover rounded-xl"
                                            />

                                            <div className="bg-white p-4 sm:p-6">
                                                <h3 className="text-lg text-gray-900">
                                                    Tablette de fidélité clients
                                                </h3>

                                                <p className="mt-2 line-clamp-3 text-sm/relaxed text-gray-700">
                                                    Laissez vos client rejoindre
                                                    votre programme de fidélité
                                                    en parfaite autonomie. Ils
                                                    pourront voir les
                                                    récompenses disponibles tout
                                                    en laissant leurs coordonnée
                                                    vous permettant de mettre en
                                                    place un marketing ciblé.
                                                    Code restaurant: 7247
                                                </p>
                                                <div className="flex gap-2 mt-4">
                                                    <input
                                                        id="tabletLink"
                                                        className="mt-4 w-full p-2 border rounded-xl text-xs"
                                                        value={`https://tab.fidiz.io/`}
                                                        disabled={true}
                                                    />
                                                    {/* Copy  */}
                                                    <TbExternalLink
                                                        size={35}
                                                        className="mt-4 text-gray-500 border rounded-xl p-1 cursor-pointer"
                                                        onClick={() => {
                                                            window.open(
                                                                `https://tab.fidiz.io/`,
                                                                "_blank",
                                                            );
                                                        }}
                                                    />
                                                    <IoMdCopy
                                                        size={35}
                                                        className="mt-4 text-gray-500 border rounded-xl p-1 cursor-pointer"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                `https://tab.fidiz.io/`,
                                                            );
                                                            toast.success(
                                                                "Lien copié dans le presse-papier",
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-span-1">
                                        <article className="overflow-hidden rounded-lg shadow transition">
                                            <img
                                                alt=""
                                                src={tabletManagerImg}
                                                className="flex h-[224px] mx-auto object-cover bg-gray-400 rounded-xl"
                                            />

                                            <div className="bg-white p-4 sm:p-6">
                                                <h3 className="mt-0.5 text-lg text-gray-900">
                                                    Tablette Manager pour votre
                                                    équipe
                                                </h3>

                                                <p className="mt-2 line-clamp-3 text-sm/relaxed text-gray-700">
                                                    Grâce au lien d'accès, votre
                                                    équipe pourra gérer les
                                                    points offerts et les
                                                    récompenses facilement. Vous
                                                    pouvez attribuer 1 point par
                                                    passage ou par euro dépensé.
                                                    Les points peuvent être
                                                    ajoutés avant ou après la
                                                    connexion du client.
                                                </p>
                                                <div className="flex gap-2 mt-4">
                                                    <input
                                                        id="tabletManagerLink"
                                                        className="mt-4 w-full p-2 border rounded-xl text-xs hover:cursor-pointer"
                                                        value={
                                                            tabletManagerLink
                                                        }
                                                        disabled={true}
                                                    />
                                                    <TbExternalLink
                                                        size={35}
                                                        className="mt-4 text-gray-500 border rounded-xl p-1 cursor-pointer"
                                                        onClick={() => {
                                                            window.open(
                                                                tabletManagerLink,
                                                                "_blank",
                                                            );
                                                        }}
                                                    />
                                                    <IoMdCopy
                                                        size={35}
                                                        className="mt-4 text-gray-500 border rounded-xl p-1 cursor-pointer"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                tabletManagerLink,
                                                            );
                                                            toast.success(
                                                                "Lien copié dans le presse-papier",
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-white shadow-md rounded-lg overflow-hidden border border-gray-200">
                                            <div className="relative pb-56">
                                                <iframe
                                                    src={`https://www.loom.com/embed/ed8928bddad24c9c8cf6dfa24f279d53?sid=d12a1380-f1dd-4556-9175-23757b96c2da`}
                                                    frameBorder="0"
                                                    allowFullScreen
                                                    className="absolute top-0 left-0 w-full h-full rounded-t-lg"
                                                    title={
                                                        "Utilisez la tablette de fidélité"
                                                    }
                                                ></iframe>
                                            </div>
                                            <div className="flex p-4 gap-2">
                                                <h3 className="mt-0.5 text-md font-bold text-gray-900">
                                                    Tutoriel vidéo: {""}
                                                </h3>
                                                <h3 className="mt-0.5 text-md text-gray-900">
                                                    Tablette de fidélité clients
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-white shadow-md rounded-lg overflow-hidden border border-gray-200">
                                            <div className="relative pb-56">
                                                <iframe
                                                    src={`https://www.loom.com/embed/fea54ee668894060b17cf9af85a11e50?sid=0d0ec574-c084-4521-9455-0abbdde7f16a"`}
                                                    frameBorder="0"
                                                    allowFullScreen
                                                    className="absolute top-0 left-0 w-full h-full rounded-t-lg"
                                                    title={
                                                        "Utilisez la tablette de fidélité"
                                                    }
                                                ></iframe>
                                            </div>
                                            <div className="flex p-4 gap-2">
                                                <h3 className="mt-0.5 text-md font-bold text-gray-900">
                                                    Tutoriel vidéo: {""}
                                                </h3>
                                                <h3 className="mt-0.5 text-md text-gray-900">
                                                    Tablette Manager pour votre
                                                    équipe
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <Card
                                        extra={
                                            "relative flex px-5 h-[580px] w-full text-4xl border"
                                        }
                                    >
                                        {/* <div className="absolute right-1 h-10 flex justify-end">
                                            <SaveStatus status={saveStatus} />
                                        </div> */}
                                        <h4 className="mt-4 text-xl font-medium">
                                            Récompenses
                                        </h4>
                                        <p className="mt-1 mb-4 text-sm">
                                            Récompenses disponibles en fonction
                                            du nombre de points cumulés.
                                        </p>
                                        {/* Clipboard copy button */}
                                        <div className="mt-3 overflow-y-scroll">
                                            {restaurant &&
                                                restaurant.rewards.map(
                                                    (
                                                        reward: any,
                                                        index: number,
                                                    ) => (
                                                        <div className="my-3 flex gap-5">
                                                            <InputField
                                                                id={`name-${index}`}
                                                                label="Récompense"
                                                                placeholder="Un menu offert"
                                                                type="text"
                                                                onChange={(
                                                                    e: any,
                                                                ) =>
                                                                    handleRewardChange(
                                                                        e,
                                                                        index,
                                                                    )
                                                                }
                                                                value={
                                                                    reward?.name
                                                                }
                                                                inputSmall={
                                                                    true
                                                                }
                                                            />
                                                            <InputField
                                                                id={`points-${index}`}
                                                                label="Nombre de points nécessaires"
                                                                placeholder="0"
                                                                type="number"
                                                                onChange={(
                                                                    e: any,
                                                                ) =>
                                                                    handleRewardChange(
                                                                        e,
                                                                        index,
                                                                    )
                                                                }
                                                                value={
                                                                    reward?.points
                                                                }
                                                                inputSmall={
                                                                    true
                                                                }
                                                            />
                                                            <TbTrash
                                                                size="25"
                                                                className="text-red-500 border rounded-xl p-1 cursor-pointer"
                                                                onClick={() => {
                                                                    setRestaurant(
                                                                        {
                                                                            ...restaurant,
                                                                            rewards:
                                                                                restaurant.rewards.filter(
                                                                                    (
                                                                                        r: any,
                                                                                        i: number,
                                                                                    ) =>
                                                                                        i !==
                                                                                        index,
                                                                                ),
                                                                        },
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    ),
                                                )}
                                        </div>
                                        <div
                                            className="flex mt-4 mb-4 w-[220px] text-sm p-1 gap-2 cursor-pointer border rounded-xl"
                                            onClick={() => {
                                                setRestaurant({
                                                    ...restaurant,
                                                    rewards: [
                                                        ...restaurant.rewards,
                                                        {
                                                            name: "",
                                                            points: 0,
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <p className="ml-3 text-sm">
                                                Ajouter une récompense
                                            </p>
                                            <BiPlusCircle
                                                size="20"
                                                className="text-[#bfdce6]"
                                            />
                                        </div>
                                    </Card>
                                    <div className="flex">
                                        <div className="h-[347px] w-full bg-white rounded-lg shadow-md overflow-hidden">
                                            <h2 className="text-xl font-bold text-center bg-primary text-white py-4">
                                                Idées de récompenses
                                            </h2>
                                            <table className="min-w-full bg-white">
                                                <thead>
                                                    <tr>
                                                        <th className="py-3 px-4 bg-gray-200 text-left text-gray-700 font-semibold">
                                                            Nombre de points
                                                        </th>
                                                        <th className="py-3 px-4 bg-gray-200 text-left text-gray-700 font-semibold">
                                                            Cadeau
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rewards.map(
                                                        (reward, index) => (
                                                            <tr
                                                                key={index}
                                                                className={
                                                                    index %
                                                                        2 ===
                                                                    0
                                                                        ? "bg-gray-100"
                                                                        : ""
                                                                }
                                                            >
                                                                <td className="py-3 px-4 text-gray-700">
                                                                    {
                                                                        reward.points
                                                                    }
                                                                </td>
                                                                <td className="py-3 px-4 text-gray-700">
                                                                    {
                                                                        reward.reward
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ),
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LiveChatFooter />
        </>
    );
};

export default TabletOnboarding;
