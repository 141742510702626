import { useEffect } from "react";
import AdminNumber from "./components/AdminNumber";

function LoyaltyHub() {
    useEffect(() => {
        localStorage.removeItem("user-token");
    }, []);

    return (
        <div className="">
            <AdminNumber />
        </div>
    );
}

export default LoyaltyHub;
