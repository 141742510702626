import { useDisclosure } from "@chakra-ui/hooks";
import DeleteModal from "components/actions/Delete";
import Card from "components/card";
import {
    archiveCustomer,
    getCustomerPage,
    getSponsorCode,
} from "interfaces/customer";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import CustomerStats from "./components/customerStats";
import GoBack from "components/actions/GoBack";
import CustomerInfoPreview from "./components/CustomerInfoPreview";
import Year from "react-calendar/dist/cjs/DecadeView/Year";
import YearlyCA from "./components/YearlyCA";
import YearlyVisit from "./components/YearlyVisits";

const EditCustomer = (props: { id?: string }) => {
    const { id } = props;
    const navigate = useNavigate();
    const params = useParams();
    const { isOpen, onClose } = useDisclosure();
    const [customer, setCustomer] = useState<any>(null);

    const initCustomer = async (id: string) => {
        const res = await getCustomerPage(id);
        if (res) {
            setCustomer(res);
            return;
        }
        //navigate("/customers");
    };

    const handleGenerateCodeClick = async () => {
        await getSponsorCode(params.id);
        window.location.reload();
    };

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/login");
        }
        initCustomer(params.id || id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-1 col-span-12">
                <GoBack text="Mes clients" />
            </div>
            <div className="mt-2 grid grid-cols-12 gap-3">
                <CustomerStats customer={customer} />
                <DeleteModal
                    isOpen={isOpen}
                    onClose={onClose}
                    description="Êtes-vous sûr de vouloir archiver ce client ?"
                    context="un client"
                    archived={true}
                    deleteFunction={() => {
                        archiveCustomer(params.id);
                        navigate("/customers");
                    }}
                />
                <CustomerInfoPreview customer={customer} />
                <Card
                    extra={
                        "w-full !p-5 border col-span-12 sm:col-span-6 relative"
                    }
                >
                    {/* Header */}
                    <div className="w-full px-[8px]">
                        <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                            Parrainage
                        </h4>
                        {customer?.sponsorCode ? (
                            <>
                                <p className="mt-1 text-sm text-gray-600">
                                    Code d'invitation : {customer?.sponsorCode}
                                </p>
                                <p className="mt-1 text-sm text-gray-600">
                                    Nombre de filleuls :{" "}
                                    {customer?.usersAffiliated}
                                </p>
                            </>
                        ) : (
                            <>
                                <p className="mt-1 text-sm text-gray-600">
                                    Aucun code d'invitation existant pour ce
                                    client
                                </p>
                                {/* Create affiliate link */}
                                <button
                                    className="mt-2 text-xs text-primary hover:underline"
                                    onClick={handleGenerateCodeClick}
                                >
                                    Générer le code d'invitation
                                </button>
                            </>
                        )}
                    </div>
                </Card>
                <div className="col-span-12">
                    <YearlyCA id={params.id} />
                </div>
                <div className="col-span-12">
                    <YearlyVisit id={params.id} />
                </div>
            </div>
        </>
    );
};

export default EditCustomer;
