import { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import ActivityChart from "./Activity";
import TopCustomers from "views/admin/main/account/billing/components/TopCustomers";
import ReactApexChart from "react-apexcharts";
import SleepingCustomers from "views/admin/main/account/billing/components/sleepingCustomers";
import { useNavigate } from "react-router-dom";
import { getDashboard } from "interfaces/user";
import PointsPerMonth from "./PointsPerMonth";

const FidelityDashboard = () => {
    const [display, setDisplay] = useState<boolean>(true);
    const nav = useNavigate();

    const [dashboardData, setDashboardData] = useState<any>([]);

    const [lineChartDataOverallRevenue, setLineChartDataOverallRevenue] =
        useState<any[]>([
            {
                data: [],
            },
        ]);

    const fetchDashboardData = async () => {
        const res = await getDashboard();
        if (res.error) {
            nav("/login");
            return;
        }
        setDashboardData(res.data);
        setLineChartDataOverallRevenue([
            {
                data: res.data?.topUsers.map((item: any) => ({
                    x: item.email,
                    y: item.points,
                })),
            },
        ]);
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    const options = {
        legend: {
            show: false,
        },
        chart: {
            toolbar: {
                show: false,
            },
            //colors: ["#417ec4", "#0260CB"],
            height: 350,
            type: "treemap",
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: "12px",
            },
            formatter: function (text: any, op: any) {
                return [text, `${op.value} points`];
            },
            offsetY: -4,
        },
    };

    return (
        <>
            <div className="flex gap-2">
                <h1 className="font-bold">Fidélité</h1>
                {display ? (
                    <IoIosArrowUp
                        className="cursor-pointer text-xl"
                        onClick={() => setDisplay(false)}
                    />
                ) : (
                    <IoIosArrowDown
                        className="cursor-pointer text-xl"
                        onClick={() => setDisplay(true)}
                    />
                )}
            </div>
            {display ? (
                <div className="col-span-3">
                    {/* <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                        <div className="flex flex-col rounded-lg bg-blue-50 px-4 py-5 text-center">
                            <dt className="order-last text-lg font-medium text-gray-500">
                                Numéros collectés
                            </dt>

                            <dd className="text-2xl font-extrabold text-red-400 md:text-3xl">
                                0
                            </dd>
                        </div>
                        <div className="flex flex-col rounded-lg bg-blue-50 px-4 py-5 text-center">
                            <dt className="order-last text-lg font-medium text-gray-500">
                                Connexion client
                            </dt>

                            <dd className="text-2xl font-extrabold text-primary md:text-3xl">
                                0
                            </dd>
                        </div>
                        <div className="flex flex-col rounded-lg bg-blue-50 px-4 py-5 text-center">
                            <dt className="order-last text-lg font-medium text-gray-500">
                                SMS envoyés
                            </dt>

                            <dd className="text-2xl font-extrabold text-primary md:text-3xl">
                                0
                            </dd>
                        </div>
                        <div className="flex flex-col rounded-lg bg-blue-50 px-4 py-5 text-center">
                            <dt className="order-last text-lg font-medium text-gray-500">
                                Emails envoyés
                            </dt>

                            <dd className="text-2xl font-extrabold text-primary md:text-3xl">
                                0
                            </dd>
                        </div>
                    </div> */}
                    {dashboardData && (
                        <>
                            <ActivityChart data={dashboardData} />
                            <PointsPerMonth data={dashboardData} />
                            <div className="mt-2 grid grid-cols-3 gap-3">
                                <TopCustomers data={dashboardData?.topUsers} />
                                <div className="border rounded-xl p-4">
                                    <h1 className="text-lg font-bold">
                                        Répartition des points de fidélité
                                    </h1>
                                    <ReactApexChart
                                        options={options as any}
                                        series={
                                            lineChartDataOverallRevenue as any
                                        }
                                        type="treemap"
                                        width="100%"
                                        height="85%"
                                    />
                                </div>

                                <SleepingCustomers
                                    data={dashboardData?.sleepingUsers}
                                />
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div className="col-span-3 grid grid-cols-1 md:grid-cols-4 gap-3"></div>
            )}
        </>
    );
};

export default FidelityDashboard;
