import { useDisclosure } from "@chakra-ui/hooks";
import DeleteModal from "components/actions/Delete";
import Card from "components/card";
import InputField from "components/fields/InputField";
import {
    archiveCustomer,
    getCustomerPage,
    updateCustomer,
} from "interfaces/customer";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import SaveStatus from "components/actions/SaveStatus";
import GoBack from "components/actions/GoBack";
import InputTagField from "components/fields/InputTagField";
import { updateMyInfo } from "interfaces/user";

const EditInformation = (props: { id?: string }) => {
    const { id } = props;
    const navigate = useNavigate();
    const params = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [customer, setCustomer] = useState<any>(null);
    const [saveStatus, setSaveStatus] = useState<any>("none");
    const [user, setUser] = useState(null);

    const addNewTag = async (newTag: string) => {
        if (!user) return;
        await updateMyInfo({ tags: [...user.tags, newTag] });
    };

    const handleChange = (e: any) => {
        setSaveStatus("saving");
        setCustomer((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const initCustomer = async (id: string) => {
        const res = await getCustomerPage(id);
        if (res) {
            setCustomer(res);
            return;
        }
        navigate("/customers");
    };

    const editCustomer = async () => {
        const data = {
            _id: customer._id,
            deliveryAddress: customer.deliveryAddress,
            notes: customer.notes,
            tags: customer.tags,
            birthDate: customer.birthDate,
            emailInfo: customer.emailInfo,
            phoneInfo: customer.phoneInfo,
            surnameInfo: customer.surnameInfo,
            nameInfo: customer.nameInfo,
        };
        const res = await updateCustomer(data);
        if (res.data) setSaveStatus("saved");
        else setSaveStatus("error");
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            editCustomer();
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/login");
        }
        initCustomer(params.id || id);
        //initUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-1 col-span-12">
                <GoBack text="Fiche client" />
            </div>
            <div className="mt-2 grid grid-cols-12 gap-3">
                <DeleteModal
                    isOpen={isOpen}
                    onClose={onClose}
                    description="Êtes-vous sûr de vouloir supprimer ce client ?"
                    context="un client"
                    deleteFunction={() => {
                        archiveCustomer(params.id);
                        navigate("/my-customers");
                    }}
                />
                <div className={`col-span-12`}>
                    <Card extra={"w-full !p-5 border"}>
                        {/* Header */}
                        <div className="w-full px-[8px]">
                            <div className="grid grid-cols-4 gap-2">
                                <h4 className="col-span-1 sm:col-span-2 text-lg font-medium text-navy-700 dark:text-white">
                                    {customer
                                        ? customer.company
                                        : "Edition client"}
                                </h4>
                            </div>
                        </div>
                        {/* inputs */}
                        <div className="grid gap-3 grid-cols-12">
                            <div className="mt-2 flex col-span-12 justify-between">
                                <div className="">
                                    <p className="text-base">Compte client</p>
                                    <p className="mt-3 text-xs">
                                        {customer?.email} ({customer?.phone})
                                    </p>
                                </div>
                                <div className="flex gap-3">
                                    <SaveStatus status={saveStatus} />
                                    <div className="col-span-2 col-start-10">
                                        <button
                                            className="linear flex cursor-pointer items-center justify-center rounded-xl border border-red-500 px-2 py-2 text-xs text-red-500 transition duration-200 hover:bg-red-50 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                            onClick={() => onOpen()}
                                        >
                                            Supprimer le client{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 grid gap-3 grid-cols-12">
                            <div className="col-span-12 lg:col-span-3">
                                <InputField
                                    label="Email"
                                    placeholder="Email"
                                    value={customer?.emailInfo}
                                    id="emailInfo"
                                    type="email"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <InputField
                                    label="Téléphone"
                                    placeholder="Téléphone"
                                    value={customer?.phoneInfo}
                                    id="phoneInfo"
                                    onChange={handleChange}
                                    type="text"
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <InputField
                                    label="Prénom"
                                    placeholder="Prénom"
                                    value={customer?.nameInfo}
                                    id="nameInfo"
                                    type="text"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <InputField
                                    label="Nom"
                                    placeholder="Nom"
                                    value={customer?.surnameInfo}
                                    id="surnameInfo"
                                    onChange={handleChange}
                                    type="text"
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-2">
                                <InputField
                                    label="Date de naissance"
                                    value={
                                        customer?.birthDate
                                            ? customer?.birthDate.split("T")[0]
                                            : ""
                                    }
                                    id="birthDate"
                                    type="date"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="z-50 col-span-12 lg:col-span-3">
                                <InputTagField
                                    label="Tags clients"
                                    placeholder=""
                                    id="tags"
                                    type="text"
                                    extra=""
                                    tags={customer?.tags}
                                    allTags={
                                        user
                                            ? user.tags.map((name: string) => ({
                                                  name,
                                              }))
                                            : []
                                    }
                                    onTagCreate={(tag: any) => addNewTag(tag)}
                                    onChange={(tags: any) =>
                                        handleChange({
                                            target: { id: "tags", value: tags },
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-6 grid">
                                <InputField
                                    label={`Notes utiles`}
                                    placeholder="Notes utiles"
                                    value={customer?.notes}
                                    id="notes"
                                    type="text"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                        </div>
                        <div className="mt-8 grid gap-3 grid-cols-12">
                            <p className="col-span-12 text-base">
                                Informations de livraison
                            </p>
                            <div className="mt-4 col-span-12 lg:col-span-6 lg:mt-0">
                                <InputField
                                    label={`Adresse de livraison`}
                                    placeholder="Champ de Mars, 5 Av. Anatole France, 75007 Paris, France"
                                    id="deliveryAddress"
                                    type="text"
                                    value={
                                        customer?.deliveryAddress
                                            ? customer?.deliveryAddress
                                            : ""
                                    }
                                    onChange={handleChange}
                                    isLoaded={customer}
                                    inputSmall={true}
                                />
                            </div>
                        </div>
                        {saveStatus === "saved" && (
                            <div className="mt-5">
                                <GoBack text="Retour à la fiche client" />
                            </div>
                        )}
                    </Card>
                </div>
            </div>
        </>
    );
};

export default EditInformation;
