import { useEffect } from "react";

function InConnect() {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        const fullName = urlParams.get("name");
        const id = urlParams.get("id");
        localStorage.setItem("token", token);
        localStorage.setItem("name", fullName);
        localStorage.setItem("id", id);
        localStorage.setItem("isAdmin", "true");
        window.location.href = "/dashboard";
    }, []);
    return <p>Loading...</p>;
}

export default InConnect;
