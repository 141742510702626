import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { updateKpis } from "interfaces/customer";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const InsertDataModal = (props: { isOpen: any; onClose: any; kpi: any }) => {
    const { isOpen, onClose, kpi } = props;
    const [metrics, setMetrics] = useState<any>({
        instaFollowers: { total: kpi?.metrics?.instaFollowers?.total },
        instaImpression: {
            total: kpi?.metrics?.instaImpression?.total,
        },
        instaPostNbr: { total: kpi?.metrics?.instaPostNbr?.total },
        instaAds: { total: kpi?.metrics?.instaAds?.total },
    });

    const handleMetricsTotalChange = (e: any) => {
        setMetrics({ ...metrics, [e.target.id]: { total: e.target.value } });
    };

    const submitMetricsChange = async () => {
        const res = await updateKpis({
            month: new Date().getMonth() + 1,
            metrics: metrics,
        });
        if (res) {
            onClose();
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="mb-[20px] text-lg font-medium">
                            Reporting
                        </h1>
                        <InputField
                            label="Followers"
                            id="instaFollowers"
                            extra="w-full"
                            value={metrics.instaFollowers.total}
                            type="number"
                            onChange={handleMetricsTotalChange}
                        />
                        <InputField
                            label="Impressions"
                            id="instaImpression"
                            extra="w-full"
                            value={metrics.instaImpression.total}
                            type="number"
                            onChange={handleMetricsTotalChange}
                        />
                        <InputField
                            label="Nombre de posts"
                            id="instaPostNbr"
                            extra="w-full"
                            value={metrics.instaPostNbr.total}
                            type="number"
                            onChange={handleMetricsTotalChange}
                        />
                        <InputField
                            label="Budget Ads Boost"
                            id="instaAds"
                            extra="w-full"
                            value={metrics.instaAds.total}
                            type="number"
                            onChange={handleMetricsTotalChange}
                        />
                        <div className="mt-5 flex gap-2 mx-auto">
                            <button
                                onClick={onClose}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={submitMetricsChange}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Sauvegarder
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const InstaDashboard = (props: { kpis: any; refresh: any }) => {
    const { kpis, refresh } = props;
    const kpi = kpis?.find(
        (kpi: any) => kpi.month === new Date().getMonth() + 1,
    );
    const previousKpi = kpis?.find(
        (kpi: any) => kpi.month === new Date().getMonth(),
    );
    const [display, setDisplay] = useState<boolean>(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            {kpi && (
                <InsertDataModal
                    isOpen={isOpen}
                    onClose={() => {
                        refresh();
                        onClose();
                    }}
                    kpi={kpi}
                />
            )}
            <div className="flex gap-2">
                <h1 className="font-bold">Instagram</h1>
                {display ? (
                    <IoIosArrowUp
                        className="cursor-pointer text-xl"
                        onClick={() => setDisplay(false)}
                    />
                ) : (
                    <IoIosArrowDown
                        className="cursor-pointer text-xl"
                        onClick={() => setDisplay(true)}
                    />
                )}
                <button
                    onClick={onOpen}
                    className="border-gray-700 text-xs justify-between"
                >
                    Faire le reporting
                </button>
            </div>
            {display ? (
                <div className="col-span-3 grid grid-cols-1 md:grid-cols-4 gap-3">
                    <div className="relative flex flex-col rounded-lg bg-blue-50 px-4 py-5 text-center">
                        <dt className="order-last text-md font-medium text-gray-500">
                            Followers
                        </dt>

                        <dd className="text-2xl font-extrabold  md:text-3xl">
                            {kpi?.metrics?.instaFollowers?.total}
                        </dd>
                        <p className="absolute right-4 top-2 text-xs text-green-500 font-bold">
                            {kpi?.metrics?.instaFollowers?.monthlyChange > 0
                                ? "+" +
                                  kpi?.metrics?.instaFollowers?.monthlyChange
                                : kpi?.metrics?.instaFollowers?.monthlyChange}
                        </p>
                    </div>
                    <div className="relative flex flex-col rounded-lg bg-blue-50 px-4 py-5 text-center">
                        <dt className="order-last text-lg font-medium text-gray-500">
                            Impressions
                        </dt>

                        <dd className="text-2xl font-extrabold  md:text-3xl">
                            {kpi?.metrics?.instaImpression?.total}
                        </dd>
                        <p className="absolute right-4 top-2 text-xs text-green-500 font-bold">
                            {kpi?.metrics?.instaImpression?.monthlyChange > 0
                                ? "+" +
                                  kpi?.metrics?.instaImpression?.monthlyChange
                                : kpi?.metrics?.instaImpression?.monthlyChange}
                        </p>
                    </div>
                    <div className="relative flex flex-col rounded-lg bg-blue-50 px-4 py-5 text-center">
                        <dt className="order-last text-lg font-medium text-gray-500">
                            Nombre de posts
                        </dt>

                        <dd className="text-2xl font-extrabold  md:text-3xl">
                            {kpi?.metrics?.instaPostNbr?.total}
                        </dd>
                        <p className="absolute right-4 top-2 text-xs text-green-500 font-bold">
                            {kpi?.metrics?.instaPostNbr?.monthlyChange > 0
                                ? "+" +
                                  kpi?.metrics?.instaPostNbr?.monthlyChange
                                : kpi?.metrics?.instaPostNbr?.monthlyChange}
                        </p>
                    </div>
                    <div className="relative flex flex-col rounded-lg bg-blue-50 px-4 py-5 text-center">
                        <dt className="order-last text-lg font-medium text-gray-500">
                            Budget Ads Boost
                        </dt>

                        <dd className="text-2xl font-extrabold  md:text-3xl">
                            {kpi?.metrics?.instaAds?.total}
                        </dd>
                        <p className="absolute right-4 top-2 text-xs text-green-500 font-bold">
                            {kpi?.metrics?.instaAds?.monthlyChange > 0
                                ? "+" + kpi?.metrics?.instaAds?.monthlyChange
                                : kpi?.metrics?.instaAds?.monthlyChange}
                        </p>
                    </div>
                </div>
            ) : (
                <div className="col-span-3 grid grid-cols-1 md:grid-cols-4 gap-3"></div>
            )}
        </>
    );
};

export default InstaDashboard;
