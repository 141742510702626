import FullCalendar from "@fullcalendar/react";
import frLocale from "@fullcalendar/core/locales/fr";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function EventCalendar(props: { calendarData: any; initialDate: string }) {
    const { calendarData, initialDate } = props;
    const nav = useNavigate();

    const renderEventContent = (eventInfo: any) => {
        return (
            <div
                className="w-full h-full center rounded-xl cursor-pointer"
                onClick={() => {
                    if (
                        eventInfo.event.extendedProps.permalink.includes(
                            "edit-media",
                        )
                    ) {
                        nav(eventInfo.event.extendedProps.permalink);
                    } else {
                        window.open(
                            eventInfo.event.extendedProps.permalink,
                            "_blank",
                        );
                    }
                }}
            >
                <div className="p-1 flex gap-1">
                    <FaInstagram className="flex my-auto text-[#515151e9]" />
                    <p className="text-[#515151e9]">
                        Post - {new Date(eventInfo.event.start).getHours()}H
                    </p>
                </div>
                <div className="ml-2">
                    {eventInfo.event.extendedProps.status === "published" ? (
                        <div className="flex gap-2">
                            <div className="flex my-auto bg-green-500 h-2 w-2 rounded-full"></div>
                            <p className="flex text-[#515151e9] text-xs">
                                Publié
                            </p>
                        </div>
                    ) : (
                        <div className="flex gap-2">
                            <div className="flex my-auto bg-[#f5a623] h-2 w-2 rounded-full"></div>
                            <p className="flex text-[#515151e9] text-xs">
                                Programmé
                            </p>
                        </div>
                    )}
                </div>
                <img
                    src={eventInfo.event.extendedProps.imageUrl}
                    alt="post"
                    className="mt-2 w-[155px] max-h-[155px] object-cover"
                />
            </div>
        );
    };

    return (
        <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            locale={frLocale}
            dayHeaderFormat={{
                weekday: "long",
                day: "numeric",
            }}
            initialView="timeGridWeek"
            events={calendarData}
            headerToolbar={{
                left: "prev,next",
                center: "", //title
                right: "",
            }}
            // slotLabelContent={(arg) => {
            //     return <span className=""></span>;
            // }}
            eventMinHeight={200}
            slotMinTime={"07:00:00"}
            slotMaxTime={"23:00:00"}
            slotDuration={"00:30:00"}
            initialDate={initialDate}
            allDaySlot={false}
            eventContent={renderEventContent}
            eventBorderColor="black"
            editable={false}
        />
    );
}

export default EventCalendar;
