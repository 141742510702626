import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const uploadMedia = async (
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .post(`/content/media`, data, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const createPost = async (
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .post(`/content/`, data, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const deletePost = async (
    id: string,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .delete(`/content/${id}`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const updatePost = async (
    id: string,
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put(`/content/${id}`, data, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getContent = async (id: string) => {
    const res: any = await axios
        .get(`/content/${id}`, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getMetaFeed = async () => {
    const res: any = await axios
        .get(`/connect/meta/feed`, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const verifyMetaIntegration = async () => {
    const res: any = await axios
        .get(`/connect/meta/verify`, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};
