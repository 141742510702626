/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import {
    renderThumb,
    renderTrack,
    renderView,
} from "components/scrollbar/Scrollbar";
import TalkImg from "assets/img/talk-logo-2.svg";
import { Scrollbars } from "react-custom-scrollbars-2";
import routes from "routes";
import Card from "components/card";
import { FaUserCog } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { useEffect, useState } from "react";
import { getAccessUrl, getSubAccounts } from "interfaces/admin";

const SidebarHorizon = (props: {
    open: boolean;
    onClose?: React.MouseEventHandler<HTMLSpanElement>;
    variant?: string;
    fullSidebar?: boolean;
}) => {
    const { open, onClose, variant, fullSidebar } = props;
    const [subAccounts, setSubAccounts] = useState<any>([]);
    const [showSubAccounts, setShowSubAccounts] = useState(false);
    const [selectedSubAccount, setSelectedSubAccount] = useState<any>(null);

    const handleSubAccountChange = async (id: any) => {
        localStorage.setItem("id", id);
        setSelectedSubAccount(id);
        const res = await getAccessUrl(id);
        if (res) {
            window.location.href = res.url;
        }
    };

    const initSubAccount = async () => {
        const subAccounts = await getSubAccounts();
        if (subAccounts) {
            setSubAccounts(subAccounts);
            setShowSubAccounts(true);
        }
    };

    useEffect(() => {
        const adminToken = localStorage.getItem("admin-token");
        const isAdmin = localStorage.getItem("isAdmin");
        const id = localStorage.getItem("id");
        if (id) setSelectedSubAccount(id);
        if (isAdmin === "true" || adminToken) {
            // Copy token to admin-token
            if (!adminToken) {
                const token = localStorage.getItem("token");
                localStorage.setItem("admin-token", token);
            }

            initSubAccount();
        }
    }, []);

    return (
        <div
            className={`sm:none linear fixed top-0 !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
                variant === "auth" ? "xl:hidden" : "xl:block"
            } ${open ? "" : "-translate-x-full"} rounded-none`}
        >
            <Card
                extra={`${
                    fullSidebar ? "w-[285px]" : "w-[140px]"
                } sm:mr-4 h-[100vh] rounded-none`}
            >
                <Scrollbars
                    autoHide
                    renderTrackVertical={renderTrack}
                    renderThumbVertical={renderThumb}
                    renderView={renderView}
                >
                    <div className="flex h-full border-r flex-col justify-between">
                        <div>
                            <span
                                className="absolute right-4 top-2 cursor-pointer sm:hidden"
                                onClick={onClose}
                            >
                                <HiX />
                            </span>
                            <div className={`flex items-center `}>
                                <img
                                    className={`mt-7 ml-[50px] ${
                                        fullSidebar ? " w-[140px]" : "w-[20px]"
                                    }`}
                                    src={TalkImg}
                                    alt="Fidiz logo"
                                />
                            </div>
                            {showSubAccounts && (
                                <div className="px-[80px]">
                                    <select
                                        name="HeadlineAct"
                                        id="HeadlineAct"
                                        className="mt-1.5 w-full rounded-lg border-gray-300 text-gray-700 sm:text-sm"
                                        onChange={(e) =>
                                            handleSubAccountChange(
                                                e.target.value,
                                            )
                                        }
                                    >
                                        {subAccounts?.map((subAccount: any) => (
                                            <option
                                                key={subAccount.id}
                                                value={subAccount.id}
                                                selected={
                                                    subAccount.id ===
                                                    selectedSubAccount
                                                }
                                            >
                                                {subAccount.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {/* Nav item */}
                            <ul className="mt-7 ml-[10px] pt-1 hidden sm:block">
                                <Links
                                    routes={routes}
                                    fullSidebar={fullSidebar}
                                    onNavClicked={() => {}}
                                />
                            </ul>
                            <ul className="ml-[10px] pt-1 block sm:hidden">
                                <Links
                                    routes={routes}
                                    fullSidebar={fullSidebar}
                                    onNavClicked={onClose}
                                />
                            </ul>
                            <div className="sm:hidden">
                                <div className="mb-6 mt-7 h-px bg-gray-200 dark:bg-white/10" />
                                <ul className="flex flex-col gap-[8px]">
                                    <li className="my-[10px] flex cursor-pointer px-8">
                                        <a href="/settings" className="ml-3">
                                            <FaUserCog
                                                className="text-inherit h-8 w-8"
                                                color="#A3AED0"
                                            />
                                        </a>
                                    </li>
                                    <li className="my-[10px] flex cursor-pointer px-8">
                                        <a
                                            href="https://fidiz.io"
                                            onClick={() => {
                                                localStorage.removeItem(
                                                    "token",
                                                );
                                            }}
                                            className="ml-3"
                                        >
                                            <IoMdLogOut
                                                className="text-inherit h-8 w-8"
                                                color="#F53939"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {/* Join others */}
                            {/* {fullSidebar && (
                                <div className="mx-3 mt-[330px] rounded-[20px] bg-lightPrimary p-6 dark:!bg-navy-700">
                                    <div className="">
                                        <h4 className="text-xl font-bold text-orange-400 dark:text-white">
                                            Allez plus loin 🚀
                                        </h4>
                                        <p className="mt-[12px] pr-2 text-base text-gray-700 dark:text-white">
                                            Avec un{" "}
                                            <a
                                                className="font-bold text-gray-900"
                                                href="/subscribe"
                                            >
                                                {" "}
                                                abonnement PRO
                                            </a>{" "}
                                            découvrez le plein potentiel de
                                            Toola !
                                        </p>
                                    </div>
                                </div>
                            )} */}
                        </div>
                    </div>
                </Scrollbars>
            </Card>
        </div>
    );
};

export default SidebarHorizon;
