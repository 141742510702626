import EventCalendar from "components/calendar/EventCalendar";
import { getMetaFeed, verifyMetaIntegration } from "interfaces/content";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Calendar = () => {
    const nav = useNavigate();
    const [feed, setFeed] = useState<any[]>([]);
    const [isMetaIntegrated, setIsMetaIntegrated] = useState(true);

    const initFeed = async () => {
        const res = await getMetaFeed();
        console.log(res);
        if (res) {
            setFeed(res);
        }
    };

    const initMetaVerify = async () => {
        const res = await verifyMetaIntegration();
        console.log(res);
        if (res) {
            setIsMetaIntegrated(res.isIntegrated);
        }
    };

    useEffect(() => {
        initFeed();
        initMetaVerify();
    }, []);

    return (
        <div className="relative">
            {/* <div className="z-10 absolute top-10 m-auto left-0 right-0 bg-black flex items-center justify-center">
                <div className="bg-white w-[600px] py-4 rounded-lg shadow-lg text-center border-2">
                    <h3 className="text-xl font-bold mb-2">
                        Bientôt disponible
                    </h3>
                    <p className="text-base text-gray-600 px-5 ">
                        Ce calendrier vous permettra de planifier vos posts
                        facilement. Restez à l'écoute pour plus d'informations !
                    </p>
                    <button
                        className="flex mx-auto mt-4 w-[300px] text-sm px-5 h-10 linear items-center justify-center rounded-xl bg-[#518dd1] py-3 font-medium text-white cursor-pointer"
                        onClick={() => nav("/integrate")}
                    >
                        Connectez déjà vos comptes
                    </button>
                </div>
            </div> */}
            <div className="z-0">
                <div className="right-2 absolute gap-2">
                    <div className="flex gap-2">
                        {isMetaIntegrated ? (
                            <div className="flex gap-2">
                                <div className="flex mx-auto my-auto gap-2 h-4 w-4 bg-green-600 rounded-full items-center justify-center"></div>
                                <p className="flex mx-auto my-auto text-xs font-semibold">
                                    Meta connecté
                                </p>
                            </div>
                        ) : (
                            <div className="flex gap-2">
                                <button
                                    className={`p-5 linear rounded-xl bg-orange-400 text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                                    onClick={() => nav("/integrate")}
                                >
                                    Connectez Meta
                                </button>
                            </div>
                        )}
                        <button
                            className={`ml-5 p-5 linear rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            onClick={() => nav("/create-media")}
                        >
                            + Post
                        </button>
                        <button
                            className={`p-5 linear rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            onClick={() => nav("/create-story")}
                        >
                            + Story
                        </button>
                    </div>
                </div>
                <EventCalendar
                    initialDate={new Date().toISOString()}
                    calendarData={feed}
                />
            </div>
        </div>
    );
};

export default Calendar;
