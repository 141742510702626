import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const getPaginatedCustomers = async (
    page: number,
    limit: number,
    search: string,
) => {
    const res: any = await axios
        .get(
            `/customers?limit=${limit}&page=${page}&search=${search}`,
            config(),
        )
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getMyOnlyRestaurantCustomers = async (
    page: number,
    limit: number,
    search: string,
    minimumPoints?: string,
) => {
    const res: any = await axios
        .get(
            `/user/customers?limit=${limit}&page=${page}&search=${search}&minimumPoints=${
                Number(minimumPoints) || 0
            }`,
            config(),
        )
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getKpis = async (page: number, limit: number) => {
    const res: any = await axios.get(`/user/kpis`, config()).catch((err) => {
        return null;
    });
    if (!res) return null;
    return res.data;
};

export const updateKpis = async (data: any) => {
    const res: any = await axios
        .put(`/user/kpis`, data, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getMyCustomersFiltered = async (
    criteria: any,
    page: number,
    limit: number,
) => {
    const res: any = await axios
        .post(`/user/customers-filtered`, { criteria, page, limit }, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const addCustomerActivity = async (
    id: string,
    content: string,
    type: string,
) => {
    const res = await axios
        .post(`/customers/${id}/activities`, { content, type }, config())
        .catch(errorHandler);
    return res.data;
};

export const addCustomerTasks = async (
    id: string,
    content: string,
    dueDate: string,
) => {
    const res = await axios
        .post(`/customers/${id}/tasks`, { content, dueDate }, config())
        .catch(errorHandler);
    return res.data;
};

export const getCustomers = async () => {
    const res: any = await axios
        .get("/customers?limit=500", config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getCustomersWithNotInvoicedOrder = async () => {
    const res: any = await axios
        .get("/customers/invoices", config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getCustomer = async (id: string) => {
    const res = await axios.get(`/customers/${id}`, config()).catch((err) => {
        return null;
    });
    if (!res) return null;
    return res.data;
};

export const getCustomerPage = async (id: string) => {
    const res = await axios
        .get(`/customers/page/${id}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getSponsorCode = async (id: string) => {
    const res = await axios
        .get(`/customers/generate-sponsor-code/${id}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getCustomerAnalytics = async (id: string) => {
    const res = await axios
        .get(`/customers/${id}/analytics`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const updateCustomer = async (customer: any) => {
    const id = customer._id;
    delete customer._id;
    const res: any = await axios
        .put(`/customers/${id}`, customer, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const archiveCustomer = async (id: string) => {
    const res: any = await axios
        .delete(`/customers/${id}`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const deleteCustomer = async (id: string) => {
    const res: any = await axios
        .delete(`/customers/${id}`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const createCustomer = async (customer: any) => {
    const res = await axios
        .post("/customers", customer, config())
        .catch(errorHandler);
    return res.data;
};

export const importCustomers = async (customers: any) => {
    const res = await axios
        .post("/customers/bulk", { customers }, config())
        .catch(errorHandler);
    return res.data;
};
