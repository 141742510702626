import { MdEuro, MdOutlineShoppingBasket } from "react-icons/md";
import { TbPigMoney, TbMoneybag } from "react-icons/tb";

import MiniStatistics from "components/card/MiniStatistics";
import { useEffect } from "react";
import { dateToFrench, dateToFrenchShort } from "utils/date";

const CustomerStats = (props: { customer: any }) => {
    const { customer } = props;

    return (
        <div className="col-span-12 grid h-full w-full grid-cols-1 gap-3 sm:gap-5 md:grid-cols-2 xl:grid-cols-4">
            <MiniStatistics
                name="Points"
                value={customer?.points || "0"}
                icon={<TbPigMoney color="primary" />}
                iconBg="bg-lightPrimary"
            />
            <MiniStatistics
                name="Dernière visite"
                value={dateToFrenchShort(customer?.lastScan) || "-"}
                icon={<MdOutlineShoppingBasket color="primary" />}
                iconBg="bg-lightPrimary"
            />
            <MiniStatistics
                name="Dépenses"
                value={"-"}
                icon={<MdEuro color="primary" />}
                iconBg="bg-lightPrimary"
            />
            <MiniStatistics
                name="SMS envoyés"
                value={"-"}
                icon={<TbMoneybag color="primary" />}
                iconBg="bg-lightPrimary"
            />
        </div>
    );
};

export default CustomerStats;
