import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
    };
};

export const getSubAccounts = async () => {
    const res: any = await axios
        .get(`/er45htrfez/subaccounts`, config())
        .catch((e) => {
            localStorage.removeItem("admin-token");
        });
    if (!res) return null;
    return res.data;
};

export const getAccessUrl = async (id: string) => {
    const res: any = await axios
        .post(`/er45htrfez/direct-inpersonate`, { id }, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};
