import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { updateKpis } from "interfaces/customer";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const InsertDataModal = (props: { isOpen: any; onClose: any; kpi: any }) => {
    const { isOpen, onClose, kpi } = props;
    const [metrics, setMetrics] = useState<any>({
        uberEatsReviewNbr: { total: kpi?.metrics?.uberEatsReviewNbr?.total },
        uberEatsAverageRating: {
            total: kpi?.metrics?.uberEatsAverageRating?.total,
        },
        uberEatsRetention: { total: kpi?.metrics?.uberEatsRetention?.total },
        uberEatsPageClick: { total: kpi?.metrics?.uberEatsPageClick?.total },
    });

    const handleMetricsTotalChange = (e: any) => {
        setMetrics({ ...metrics, [e.target.id]: { total: e.target.value } });
    };

    const submitMetricsChange = async () => {
        const res = await updateKpis({
            month: new Date().getMonth() + 1,
            metrics: metrics,
        });
        if (res) {
            onClose();
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="mb-[20px] text-lg font-medium">
                            Reporting
                        </h1>
                        <InputField
                            label="Nombre d'avis"
                            id="uberEatsReviewNbr"
                            extra="w-full"
                            value={metrics.uberEatsReviewNbr.total}
                            type="number"
                            onChange={handleMetricsTotalChange}
                        />
                        <InputField
                            label="Note"
                            id="uberEatsAverageRating"
                            extra="w-full"
                            value={metrics.uberEatsAverageRating.total}
                            type="number"
                            onChange={handleMetricsTotalChange}
                        />
                        <InputField
                            label="Clients réguliers"
                            id="uberEatsRetention"
                            extra="w-full"
                            value={metrics.uberEatsRetention.total}
                            type="number"
                            onChange={handleMetricsTotalChange}
                        />
                        <InputField
                            label="Clics depuis GMB"
                            id="uberEatsPageClick"
                            extra="w-full"
                            value={metrics.uberEatsPageClick.total}
                            type="number"
                            onChange={handleMetricsTotalChange}
                        />
                        <div className="mt-5 flex gap-2 mx-auto">
                            <button
                                onClick={onClose}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={submitMetricsChange}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Sauvegarder
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const UberDashboard = (props: { kpis: any; refresh: any }) => {
    const { kpis, refresh } = props;
    const kpi = kpis?.find(
        (kpi: any) => kpi.month === new Date().getMonth() + 1,
    );
    const previousKpi = kpis?.find(
        (kpi: any) => kpi.month === new Date().getMonth(),
    );
    const [display, setDisplay] = useState<boolean>(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            {kpi && (
                <InsertDataModal
                    isOpen={isOpen}
                    onClose={() => {
                        refresh();
                        onClose();
                    }}
                    kpi={kpi}
                />
            )}
            <div className="flex gap-2">
                <h1 className="font-bold">UberEats</h1>
                {display ? (
                    <IoIosArrowUp
                        className="cursor-pointer text-xl"
                        onClick={() => setDisplay(false)}
                    />
                ) : (
                    <IoIosArrowDown
                        className="cursor-pointer text-xl"
                        onClick={() => setDisplay(true)}
                    />
                )}
                <button
                    onClick={onOpen}
                    className="border-gray-700 text-xs justify-between"
                >
                    Faire le reporting
                </button>
            </div>
            {display ? (
                <div className="col-span-3 grid grid-cols-1 md:grid-cols-4 gap-3">
                    <div className="relative flex flex-col rounded-lg bg-blue-50 px-4 py-5 text-center">
                        <dt className="order-last text-md font-medium text-gray-500">
                            Nombre d'avis
                        </dt>

                        <dd className="text-2xl font-extrabold  md:text-3xl">
                            {kpi?.metrics?.uberEatsReviewNbr?.total}
                        </dd>
                        <p className="absolute right-4 top-2 text-xs text-green-500 font-bold">
                            {kpi?.metrics?.uberEatsReviewNbr?.monthlyChange > 0
                                ? "+" +
                                  kpi?.metrics?.uberEatsReviewNbr?.monthlyChange
                                : kpi?.metrics?.uberEatsReviewNbr
                                      ?.monthlyChange}
                        </p>
                    </div>
                    <div className="relative flex flex-col rounded-lg bg-blue-50 px-4 py-5 text-center">
                        <dt className="order-last text-lg font-medium text-gray-500">
                            Note
                        </dt>

                        <dd className="text-2xl font-extrabold  md:text-3xl">
                            {kpi?.metrics?.uberEatsAverageRating?.total}
                        </dd>
                        <p className="absolute right-4 top-2 text-xs text-green-500 font-bold">
                            {kpi?.metrics?.uberEatsAverageRating
                                ?.monthlyChange > 0
                                ? "+" +
                                  kpi?.metrics?.uberEatsAverageRating
                                      ?.monthlyChange
                                : kpi?.metrics?.uberEatsAverageRating
                                      ?.monthlyChange}
                        </p>
                    </div>
                    <div className="relative flex flex-col rounded-lg bg-blue-50 px-4 py-5 text-center">
                        <dt className="order-last text-lg font-medium text-gray-500">
                            Clients réguliers
                        </dt>

                        <dd className="text-2xl font-extrabold  md:text-3xl">
                            {kpi?.metrics?.uberEatsRetention?.total}
                        </dd>
                        <p className="absolute right-4 top-2 text-xs text-green-500 font-bold">
                            {kpi?.metrics?.uberEatsRetention?.monthlyChange > 0
                                ? "+" +
                                  kpi?.metrics?.uberEatsRetention?.monthlyChange
                                : kpi?.metrics?.uberEatsRetention
                                      ?.monthlyChange}
                        </p>
                    </div>
                    <div className="relative flex flex-col rounded-lg bg-blue-50 px-4 py-5 text-center">
                        <dt className="order-last text-lg font-medium text-gray-500">
                            Clics depuis GMB
                        </dt>

                        <dd className="text-2xl font-extrabold  md:text-3xl">
                            {kpi?.metrics?.uberEatsPageClick?.total}
                        </dd>
                        <p className="absolute right-4 top-2 text-xs text-green-500 font-bold">
                            {kpi?.metrics?.uberEatsPageClick?.monthlyChange > 0
                                ? "+" +
                                  kpi?.metrics?.uberEatsPageClick?.monthlyChange
                                : kpi?.metrics?.uberEatsPageClick
                                      ?.monthlyChange}
                        </p>
                    </div>
                </div>
            ) : (
                <div className="col-span-3 grid grid-cols-1 md:grid-cols-4 gap-3"></div>
            )}
        </>
    );
};

export default UberDashboard;
