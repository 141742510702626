import UberDashboard from "./components/UberDashboard";
import InstaDashboard from "./components/InstaDashboard";
import FidelityDashboard from "./components/FidelityDashboard";
import GmbDashboard from "./components/gmbDashboard";
import { getKpis } from "interfaces/customer";
import { useEffect, useState } from "react";

const Performance = () => {
    const [kpis, setKpis] = useState<any>(null);

    const initKpis = async () => {
        const res = await getKpis(1, 1);
        if (res) setKpis(res);
        console.log(res);
    };

    useEffect(() => {
        initKpis();
    }, []);

    return (
        <>
            <div className="mt-8 md:mt-0 grid grid-cols-1 md:grid-cols-3 gap-3">
                {/* GMB */}
                <GmbDashboard kpis={kpis} refresh={initKpis} />
                {/* Instagram */}
                <InstaDashboard kpis={kpis} refresh={initKpis} />
                {/* UberEats */}
                <UberDashboard kpis={kpis} refresh={initKpis} />
                {/* Fidélité */}
                <FidelityDashboard />
            </div>
        </>
    );
};

export default Performance;
