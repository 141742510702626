import { getTabletNextReward, loginIfPhoneExists } from "interfaces/tablet";
import { useEffect, useState } from "react";
import { IoReload } from "react-icons/io5";
import { useParams } from "react-router-dom";

const Numpad = (props: { onSubmit: any }) => {
    const { onSubmit } = props;
    const [phone, setPhone] = useState("");
    const [error, setError] = useState(false);
    const [focusedButton, setFocusedButton] = useState(null);

    const handleButtonClick = (value: any) => {
        setFocusedButton(value);
        setTimeout(() => {
            setFocusedButton(null);
        }, 1000);

        if (error) {
            setError(false);
        }

        if (value === "C") {
            setPhone((prev) => prev.slice(0, -1));
        } else if (value === "✓") {
            alert(`You entered: ${phone}`);
            setPhone("");
        } else {
            if (phone.length >= 10) return;
            setPhone(phone + value);
        }
    };

    const handleEnter = () => {
        if (phone.length < 10) {
            setError(true);
            return;
        }
        onSubmit(phone);
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4">
            <div className="bg-white rounded-lg p-4 w-full max-w-sm">
                <div className="mb-4">
                    {/* add space every 2 charactire */}
                    <p className="w-full p-4 text-3xl text-center font-bold">
                        {phone.replace(/(.{2})/g, "$1 ")}
                    </p>
                    <p className="text-red-500 font-bold text-sm text-center">
                        {error && "Numéro de téléphone invalide"}
                    </p>
                </div>
                <div className="mt-10 grid grid-cols-3 gap-4">
                    {["1", "2", "3", "4", "5", "6", "7", "8", "9"].map(
                        (item) => (
                            <button
                                key={item}
                                onClick={() => handleButtonClick(item)}
                                className={`flex items-center justify-center h-16 bg-white text-gray-800 text-2xl rounded-full font-bold transition
                        ${
                            focusedButton === item
                                ? "bg-gray-400"
                                : "hover:bg-gray-200"
                        }`}
                            >
                                {item}
                            </button>
                        ),
                    )}
                </div>
                {/* 0 centered */}
                <div className="mt-4 grid grid-cols-3 gap-4">
                    <button
                        onClick={() => handleButtonClick("C")}
                        className={`flex items-center justify-center h-16 bg-white text-red-500 text-2xl rounded-full font-bold transition
                        ${
                            focusedButton === "<"
                                ? "bg-gray-400"
                                : "hover:bg-gray-200"
                        }`}
                    >
                        {"<"}
                    </button>
                    <button
                        onClick={() => handleButtonClick("0")}
                        className={`flex items-center justify-center h-16 bg-white text-gray-800 text-2xl rounded-full font-bold transition
                        ${
                            focusedButton === "0"
                                ? "bg-gray-400"
                                : "hover:bg-gray-200"
                        }`}
                    >
                        0
                    </button>
                    <button
                        onClick={() => handleEnter()}
                        className={`flex items-center justify-center h-16 bg-white text-green-500 text-2xl rounded-full font-bold transition
                        ${
                            focusedButton === "✓"
                                ? "bg-gray-400"
                                : "hover:bg-gray-200"
                        }`}
                    >
                        ✓
                    </button>
                </div>
            </div>
        </div>
    );
};

function AskNumber(props: { onSubmit: any; reloadNextReward: any }) {
    const { onSubmit, reloadNextReward } = props;
    const params = useParams();
    const [nextReward, setNextReward] = useState(0);
    const restaurantId = params.id;

    const handleSubmit = async (phone: string) => {
        const result = await loginIfPhoneExists(phone, restaurantId);
        if (result?.email && result?.token) {
            onSubmit(phone, result.email, result.token);
        } else if (result) {
            onSubmit(phone, null, null);
        } else {
            alert("Impossible de se connecter au système");
        }
    };

    const initNextReward = async () => {
        const res = await getTabletNextReward(restaurantId);
        if (res) {
            setNextReward(res.nextReward);
        }
    };

    useEffect(() => {
        initNextReward();
    }, [reloadNextReward]);

    // Call initNextReward every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            initNextReward();
        }, 5000);
    }, []);

    return (
        <div className="relative grid grid-cols-1 md:grid-cols-2 min-h-screen">
            <div className="bg-[#9191e9] shadow-2xl rounded-sm min-h-screen">
                {/* Center text middle of screen */}
                <div className="ml-10 mt-[30%]">
                    <h1 className="text-white text-[40px] xl:text-[45px] font-bold pt-10">
                        Merci pour votre visite !
                    </h1>
                    {nextReward === 0 && (
                        <p className="text-white text-xl mt-3">
                            Entre ton numéro pour gagner des points de fidélité
                        </p>
                    )}
                    {nextReward > 0 && (
                        <p className="text-white text-xl mt-3">
                            Entre ton numéro pour gagner {nextReward}{" "}
                            {nextReward > 1 ? "points" : "point"} de fidélité
                        </p>
                    )}
                </div>
            </div>
            <Numpad onSubmit={handleSubmit} />
            <div className="absolute bottom-2 left-4 text-white">
                <IoReload
                    className="inline-block cursor-pointer action:bg-gray-200 rounded-xl"
                    onClick={initNextReward}
                    size={20}
                />
            </div>
        </div>
    );
}

export default AskNumber;
